/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';

import {
  FETCH_IMAGES,
} from '../actions.type';

import {
  SET_IMAGES_REQUEST,
  SET_IMAGES,
} from '../mutations.type';

const state = {
  request: {},
  images: {},
};

const actions = {
  async [FETCH_IMAGES]({ commit }) {
    if (isEmpty(state.request)) {
      commit(SET_IMAGES_REQUEST, {
        isLoading: true,
        isError: false,
      });
      try {
        const { data: { images } } = await Vue.axios.get('images', {
          'axios-retry': {
            retries: 5,
            beforeTry: () => {
              commit(SET_IMAGES_REQUEST, {
                isLoading: true,
                isError: false,
              });
            },
            afterTry: ({ delay, lastRequestTime }) => {
              commit(SET_IMAGES_REQUEST, {
                isLoading: false,
                isError: true,
                delay,
                lastRequestTime,
              });
            },
          },
        });
        commit(SET_IMAGES_REQUEST, {
          isSuccess: true,
        });
        commit(SET_IMAGES, images);
      } catch (e) {
        commit(SET_IMAGES_REQUEST, {
          isFatalError: true,
        });
      }
    }
  },
};

const mutations = {
  [SET_IMAGES_REQUEST](state, request) {
    state.request = request;
  },
  [SET_IMAGES](state, images) {
    state.images = images;
  },
};

export default {
  state,
  mutations,
  actions,
};
