<template>
  <b-container class="mt-5">
    <h1 :class="[$style.h1, 'text-center']">
      Comestudy:admin
    </h1>
    <b-row class="mt-3">
      <b-col
        sm="8"
        offset-sm="2"
        md="6"
        offset-md="3"
        lg="4"
        offset-lg="4"
      >
      <b-alert
        :show="Object.keys(serverError).length > 0"
        variant="danger"
      >
        <b>Ошибка {{ serverError.description }}</b>
        <template
          v-if="serverError.status !== 422"
        >
          {{ serverError.status }}
        </template>
        <template v-else>
          <p class="mb-0">Неправильно указан адрес эл. почты или пароль</p>
        </template>
      </b-alert>
        <b-form @submit.stop.prevent="onSubmit">
        <b-form-group
          label="Эл. почта"
          label-for="email-input"
        >
          <b-form-input
            id="email-input"
            v-model.trim="$v.form.email.$model"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            aria-describedby="email-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            id="email-live-feedback"
          >
            <div v-if="!$v.form.email.required">Обязательное поле</div>
            <div v-if="!$v.form.email.email">Должно быть адресом эл. почты</div>
            <template v-if="!$v.form.email.serverValidationError">
              <div
                v-for="(error, index) in serverValidationErrors.email"
                :key="`email-serverValidationError-${index}`"
              >
                {{ error }}
              </div>
            </template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Пароль"
          label-for="password-input"
        >
          <b-form-input
            id="password-input"
            type="password"
            v-model.trim="$v.form.password.$model"
            :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
            aria-describedby="password-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            id="password-live-feedback"
          >
            <div v-if="!$v.form.password.required">Поле обязательно для заполнения</div>
            <template v-if="!$v.form.password.serverValidationError">
              <div
                v-for="(error, index) in serverValidationErrors.password"
                :key="`password-serverValidationError-${index}`"
              >
                {{ error }}
              </div>
            </template>
          </b-form-invalid-feedback>
        </b-form-group>
          <b-button
            class="mt-3"
            type="submit"
            variant="primary"
            :disabled="isLoading"
          >
            <template v-if="isLoading">
              <b-spinner class="mr-2" small></b-spinner> Загрузка
            </template>
            <template v-else>
              Войти
            </template>
          </b-button>
      </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
} from 'vuelidate/lib/validators';

import {
  AUTHENTICATE,
} from '@/store/actions.type';

export default {
  name: 'LoginPage',
  mixins: [
    validationMixin,
  ],
  data() {
    return {
      isLoading: false,
      form: {
        email: undefined,
        password: undefined,
      },
      serverValidationErrors: {},
      serverError: {},
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.serverValidationErrors = {};
        this.serverError = {};
      },
    },
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
          serverValidationError: () => !this.serverValidationErrors.email,
        },
        password: {
          required,
          serverValidationError: () => !this.serverValidationErrors.password,
        },
      },
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      try {
        this.isLoading = true;
        const { data: { token } } = await this.axios.post('/auth/login', this.form);

        if (!token) {
          throw new Error('Ошибка сервера');
        }

        this.$store.dispatch(AUTHENTICATE, token);
        this.$router.push({ name: 'Main' });
      } catch (e) {
        this.serverError = {
          status: e.response ? e.response.status : undefined,
          description: e.response && e.response.status === 422 ? 'валидации' : 'сервера',
        };
        this.serverValidationErrors = e.response && e.response.data && e.response.data.errors
          ? e.response.data.errors
          : {};
      }
      this.isLoading = false;
    },
    onReset() {
      this.form.email = undefined;
      this.form.password = undefined;
    },
  },
};
</script>

<style module lang="stylus">
.h1
  font-size 24px
</style>
