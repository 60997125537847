import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import { getToken } from './jwt.service';
import axiosRetry, { exponentialDelay } from './axios-retry';
import { PURGE_AUTH } from '../store/mutations.type';

const AxiosService = {
  init(router, store) {
    Vue.use(VueAxios, axios);

    axiosRetry(Vue.axios, {
      retries: 0,
      retryDelay: exponentialDelay,
    });

    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

    Vue.axios.interceptors.response.use((response) => response, (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          store.commit(PURGE_AUTH);
          if (router.currentRoute.name !== 'Login') {
            router.push({ name: 'Login' });
          }
        }
      }
      return Promise.reject(error);
    });
  },

  setHeader() {
    const token = getToken();
    if (token) {
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  },
};

export default AxiosService;
