/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';

import {
  FETCH_LANGUAGES,
} from '../actions.type';

import {
  SET_LANGUAGES_REQUEST,
  SET_LANGUAGES,
  SET_CURRENT_LANGUAGE,
} from '../mutations.type';

const state = {
  request: {},
  languages: [],
  current: undefined,
};

const getters = {
  languagesBySlug: (state) => keyBy(state.languages, 'slug'),
};

const actions = {
  async [FETCH_LANGUAGES]({ commit }) {
    try {
      const { data: { languages } } = await Vue.axios.get('languages', {
        'axios-retry': {
          retries: 5,
          beforeTry: () => {
            commit(SET_LANGUAGES_REQUEST, {
              isLoading: true,
              isError: false,
            });
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_LANGUAGES_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime,
            });
          },
        },
      });
      commit(SET_LANGUAGES_REQUEST, {
        isSuccess: true,
      });
      commit(SET_LANGUAGES, languages);
      commit(SET_CURRENT_LANGUAGE, find(languages, 'isDefault').slug);
    } catch (e) {
      commit(SET_LANGUAGES_REQUEST, {
        isFatalError: true,
      });
    }
  },
};

const mutations = {
  [SET_LANGUAGES_REQUEST](state, request) {
    state.request = request;
  },
  [SET_LANGUAGES](state, languages) {
    state.languages = languages;
  },
  [SET_CURRENT_LANGUAGE](state, languageSlug) {
    state.current = languageSlug;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
