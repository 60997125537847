var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-light navbar-light navBar py-2"},[_c('b-container',[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.navBar-collapse",modifiers:{"navBar-collapse":true}}],staticClass:"navbar-toggler d-lg-none"},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('b-button',{staticClass:"ml-2 ml-lg-0 text-decoration-none text-reset text-left font-weight-bold",attrs:{"variant":"link","to":{
          name: 'Main',
        }}},[_vm._v(" Comestudy ")]),_c('b-dropdown',{staticClass:"ml-auto",attrs:{"right":"","size":"sm","variant":"outline-secondary","disabled":!_vm.languages.request.isSuccess},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.languages.request.isLoading || _vm.languages.request.isError)?[_c('b-spinner',{attrs:{"small":""}})]:_vm._e(),(_vm.languages.request.isFatalError)?[_vm._v(" - ")]:[_vm._v(" "+_vm._s(_vm.languages.current)+" ")]]},proxy:true}])},_vm._l((_vm.languages.value),function(language){return _c('b-dropdown-item',{key:("navBar-languages-" + (language.id)),attrs:{"active":language.slug === _vm.languages.current},on:{"click":function($event){return _vm.handleLanguageSelected(language.slug)}}},[_vm._v(" "+_vm._s(language.slug)+" ")])}),1)],1),_c('b-collapse',{staticClass:"d-lg-block mt-2",attrs:{"id":"navBar-collapse"}},[_c('b-button',{staticClass:"text-decoration-none text-left",attrs:{"variant":"link","block":""}},[_vm._v(" Изображения ")]),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.navBar-pages",modifiers:{"navBar-pages":true}}],class:[
          'font-weight-bold text-decoration-none text-left',
          {
            'dropdown-toggle': _vm.pages.request.isSuccess,
          } ],attrs:{"variant":"link","disabled":!_vm.pages.request.isSuccess,"block":""}},[_vm._v(" Страницы "),(_vm.pages.request.isLoading || _vm.pages.request.isError)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1),(_vm.pages.request.isSuccess)?_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"navBar-pages"}},[_c('b-card',_vm._l((_vm.pages.value),function(page){return _c('b-button',{key:("navBar-pages-" + (page.id)),staticClass:"text-decoration-none text-left",attrs:{"variant":"link","block":""}},[_vm._v(" "+_vm._s(page.uri)+" ")])}),1)],1):_vm._e(),_c('b-button',{staticClass:"text-decoration-none text-left",attrs:{"variant":"link","block":""}},[_vm._v(" Университеты ")]),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.navBar-blog",modifiers:{"navBar-blog":true}}],staticClass:"dropdown-toggle font-weight-bold text-decoration-none text-left",attrs:{"variant":"link","block":""}},[_vm._v(" Блог ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"navBar-blog"}},[_c('b-card',[_c('b-button',{staticClass:"text-decoration-none text-left",attrs:{"variant":"link","block":"","to":{
              name: 'BlogPostIndex',
            }}},[_vm._v(" Посты ")]),_c('b-button',{staticClass:"text-decoration-none text-left",attrs:{"variant":"link","block":"","to":{
              name: 'BlogTopicIndex',
            }}},[_vm._v(" Темы ")]),_c('b-button',{staticClass:"text-decoration-none text-left",attrs:{"variant":"link","block":"","to":{
              name: 'BlogAuthorIndex',
            }}},[_vm._v(" Авторы ")])],1)],1),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.navBar-settings",modifiers:{"navBar-settings":true}}],staticClass:"dropdown-toggle font-weight-bold text-decoration-none text-left",attrs:{"variant":"link","block":""}},[_vm._v(" Настройки ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"navBar-settings"}},[_c('b-card',[_c('b-button',{staticClass:"text-decoration-none text-left",attrs:{"variant":"link","block":""}},[_vm._v(" Языки ")])],1)],1),_c('b-button',{staticClass:"text-decoration-none text-left",attrs:{"variant":"link","block":""}},[_vm._v(" Выйти ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }