/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { getToken, saveToken, destroyToken } from '@/services/jwt.service';
import AxiosService from '@/services/axios.service';

import {
  AUTHENTICATE,
  CHECK_AUTH,
  FETCH_INITIAL_APP_DATA,
} from '../actions.type';

import {
  SET_AUTH,
  PURGE_AUTH,
} from '../mutations.type';

const state = {
  isAuthenticated: !!getToken(),
};

const actions = {
  [AUTHENTICATE]({ commit, dispatch }, token) {
    commit(SET_AUTH, token);
    dispatch(FETCH_INITIAL_APP_DATA);
  },
  [CHECK_AUTH]({ dispatch }) {
    if (state.isAuthenticated) {
      AxiosService.setHeader();
      dispatch(FETCH_INITIAL_APP_DATA);
    }
  },
};

const mutations = {
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    saveToken(token);
    AxiosService.setHeader();
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    destroyToken();
    AxiosService.setHeader();
  },
};

export default {
  state,
  mutations,
  actions,
};
