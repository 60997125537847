import Vue from 'vue';
import Vuex from 'vuex';

import {
  FETCH_INITIAL_APP_DATA,
  FETCH_USER,
  FETCH_LANGUAGES,
  FETCH_PAGES,
} from './actions.type';

import {
  SET_CURRENT_TIMESTAMP,
} from './mutations.type';

import auth from './modules/auth.module';
import user from './modules/user.module';
import language from './modules/language.module';
import page from './modules/page.module';
import image from './modules/image.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    timestamp: Date.now(),
  },
  mutations: {
    [SET_CURRENT_TIMESTAMP](state, timestamp) {
      state.timestamp = timestamp;
    },
  },
  actions: {
    [FETCH_INITIAL_APP_DATA]({ dispatch }) {
      dispatch(FETCH_USER);
      dispatch(FETCH_LANGUAGES);
      dispatch(FETCH_PAGES);
    },
  },
  modules: {
    auth,
    user,
    language,
    page,
    image,
  },
  plugins: [(store) => {
    const timeout = () => {
      setTimeout(() => {
        store.commit(SET_CURRENT_TIMESTAMP, Date.now());
        timeout();
      }, 1000);
    };
    timeout();
  }],
});
