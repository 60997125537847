/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue';

import {
  FETCH_PAGES,
} from '../actions.type';

import {
  SET_PAGES_REQUEST,
  SET_PAGES,
} from '../mutations.type';

const state = {
  request: {},
  pages: [],
};

const getters = {};

const actions = {
  async [FETCH_PAGES]({ commit }) {
    try {
      const { data: { pages } } = await Vue.axios.get('pages', {
        'axios-retry': {
          retries: 5,
          beforeTry: () => {
            commit(SET_PAGES_REQUEST, {
              isLoading: true,
              isError: false,
            });
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_PAGES_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime,
            });
          },
        },
      });
      commit(SET_PAGES_REQUEST, {
        isSuccess: true,
      });
      commit(SET_PAGES, pages);
    } catch (e) {
      commit(SET_PAGES_REQUEST, {
        isFatalError: true,
      });
    }
  },
};

const mutations = {
  [SET_PAGES_REQUEST](state, request) {
    state.request = request;
  },
  [SET_PAGES](state, pages) {
    state.pages = pages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
