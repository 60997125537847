import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';
import middlewarePipeline from './middleware';
import guest from './middleware/guest';
import auth from './middleware/auth';

import LoginPage from '../pages/auth/LoginPage.vue';
import LanguageIndexPage from '../pages/language/LanguageIndexPage.vue';
// import AdminIndexPage from '../pages/admin/AdminIndexPage.vue';
// import ProfilePage from '../pages/ProfilePage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      middleware: [
        guest,
      ],
    },
  },
  {
    name: 'Main',
    path: '/',
    redirect: {
      name: 'LanguageIndex',
    },
  },
  {
    path: '/languages',
    name: 'LanguageIndex',
    component: LanguageIndexPage,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  // {
  //   path: '/resources',
  //   name: 'ResourceIndex',
  //   component: () => import('../pages/resource/ResourceIndexPage.vue'),
  //   meta: {
  //     middleware: [
  //       auth,
  //     ],
  //   },
  // },
  // {
  //   path: '/pages',
  //   name: 'PageIndex',
  //   component: () => import('../pages/page/PageIndexPage.vue'),
  //   meta: {
  //     middleware: [
  //       auth,
  //     ],
  //   },
  // },
  // {
  //   path: '/components',
  //   name: 'ComponentIndex',
  //   component: () => import('../pages/component/ComponentIndexPage.vue'),
  //   meta: {
  //     middleware: [
  //       auth,
  //     ],
  //   },
  // },
  // {
  //   path: '/menus',
  //   name: 'MenuIndex',
  //   component: () => import('../pages/menu/MenuIndexPage.vue'),
  //   meta: {
  //     middleware: [
  //       auth,
  //     ],
  //   },
  // },
  {
    path: '/blogs/posts',
    name: 'BlogPostIndex',
    component: () => import('../pages/blog/post/BlogPostIndexPage.vue'),
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/blogs/posts/create',
    name: 'BlogPostCreate',
    component: () => import('../pages/blog/post/BlogPostCreatePage.vue'),
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/blogs/posts/:id',
    name: 'BlogPostEdit',
    component: () => import('../pages/blog/post/BlogPostEditPage.vue'),
    props: true,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/blogs/topics',
    name: 'BlogTopicIndex',
    component: () => import('../pages/blog/topic/BlogTopicIndexPage.vue'),
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/blogs/authors',
    name: 'BlogAuthorIndex',
    component: () => import('../pages/blog/author/BlogAuthorIndexPage.vue'),
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  // {
  //   path: '/admins',
  //   name: 'AdminIndex',
  //   component: AdminIndexPage,
  //   meta: {
  //     middleware: [
  //       auth,
  //     ],
  //   },
  // },
  // {
  //   path: '/profile',
  //   name: 'Profile',
  //   component: ProfilePage,
  //   meta: {
  //     middleware: [
  //       auth,
  //     ],
  //   },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const { middleware } = to.meta;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
