<template>
  <div class="bg-light navbar-light navBar py-2">
    <b-container>
      <div class="d-flex">
        <b-button
          class="navbar-toggler d-lg-none"
          v-b-toggle.navBar-collapse
        >
          <span class="navbar-toggler-icon"></span>
        </b-button>
        <b-button
          class="ml-2 ml-lg-0 text-decoration-none text-reset text-left font-weight-bold"
          variant="link"
          :to="{
            name: 'Main',
          }"
        >
          Comestudy
        </b-button>
        <b-dropdown
          right
          class="ml-auto"
          size="sm"
          variant="outline-secondary"
          :disabled="!languages.request.isSuccess"
        >
          <template #button-content>
            <template v-if="languages.request.isLoading || languages.request.isError">
              <b-spinner small/>
            </template>
            <template v-if="languages.request.isFatalError">
              -
            </template>
            <template v-else>
              {{ languages.current }}
            </template>
          </template>
          <b-dropdown-item
            v-for="language in languages.value"
            :key="`navBar-languages-${language.id}`"
            :active="language.slug === languages.current"
            @click="handleLanguageSelected(language.slug)"
          >
            {{ language.slug }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <b-collapse id="navBar-collapse" class="d-lg-block mt-2">
        <b-button
          class="text-decoration-none text-left"
          variant="link"
          block
        >
          Изображения
        </b-button>
        <b-button
          :class="[
            'font-weight-bold text-decoration-none text-left',
            {
              'dropdown-toggle': pages.request.isSuccess,
            },
          ]"
          variant="link"
          :disabled="!pages.request.isSuccess"
          block
          v-b-toggle.navBar-pages
        >
          Страницы <b-spinner small v-if="pages.request.isLoading || pages.request.isError"/>
        </b-button>
        <b-collapse id="navBar-pages" class="mt-2" v-if="pages.request.isSuccess">
          <b-card>
            <b-button
              class="text-decoration-none text-left"
              variant="link"
              block
              v-for="page in pages.value"
              :key="`navBar-pages-${page.id}`"
            >
              {{ page.uri }}
            </b-button>
          </b-card>
        </b-collapse>
        <b-button
          class="text-decoration-none text-left"
          variant="link"
          block
        >
          Университеты
        </b-button>
        <b-button
          class="dropdown-toggle font-weight-bold text-decoration-none text-left"
          variant="link"
          block
          v-b-toggle.navBar-blog
        >
          Блог
        </b-button>
        <b-collapse id="navBar-blog" class="mt-2">
          <b-card>
            <b-button
              class="text-decoration-none text-left"
              variant="link"
              block
              :to="{
                name: 'BlogPostIndex',
              }"
            >
              Посты
            </b-button>
            <b-button
              class="text-decoration-none text-left"
              variant="link"
              block
              :to="{
                name: 'BlogTopicIndex',
              }"
            >
              Темы
            </b-button>
            <b-button
              class="text-decoration-none text-left"
              variant="link"
              block
              :to="{
                name: 'BlogAuthorIndex',
              }"
            >
              Авторы
            </b-button>
          </b-card>
        </b-collapse>
        <b-button
          class="dropdown-toggle font-weight-bold text-decoration-none text-left"
          variant="link"
          block
          v-b-toggle.navBar-settings
        >
          Настройки
        </b-button>
        <b-collapse id="navBar-settings" class="mt-2">
          <b-card>
            <b-button
              class="text-decoration-none text-left"
              variant="link"
              block
            >
              Языки
            </b-button>
          </b-card>
        </b-collapse>
        <b-button
          class="text-decoration-none text-left"
          variant="link"
          block
        >
          Выйти
        </b-button>
      </b-collapse>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { SET_CURRENT_LANGUAGE } from '../../store/mutations.type';

export default {
  name: 'NavBar',
  computed: {
    ...mapState({
      pages: ({ page: { pages: value, request } }) => ({
        request,
        value,
      }),
      languages: ({ language: { languages: value, request, current } }) => ({
        request,
        value,
        current,
      }),
    }),
  },
  methods: {
    handleLanguageSelected(slug) {
      this.$store.commit(SET_CURRENT_LANGUAGE, slug);
    },
  },
};
</script>

<style lang="stylus" scoped>
.navBar
  @media(min-width 992px)
    width 250px
</style>
