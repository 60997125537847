<template>
  <div class="d-flex flex-column flex-lg-row min-vh-100">
    <nav-bar v-if="isAuthenticated"/>
    <div class="flex-grow-1">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CHECK_AUTH } from './store/actions.type';

import NavBar from './components/layout/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
    // nextAuthTry() {
    //   if (!this.auth.request.isError) {
    //     return 0;
    //   }
    //   const ms = this.auth.request.delay - (this.timestamp - this.auth.request.lastRequestTime);
    //   return Math.floor(ms / 1000) + 1;
    // },
  },
  beforeMount() {
    this.$store.dispatch(CHECK_AUTH);
  },
};
</script>

<style lang="stylus" scoped>

</style>
