export const SET_CURRENT_TIMESTAMP = 'setCurrentTimestamp';

export const SET_AUTH = 'setAuth';
export const PURGE_AUTH = 'purgeAuth';

export const SET_USER = 'setUser';
export const SET_USER_REQUEST = 'setUserRequest';

export const SET_LANGUAGES = 'setLanguages';
export const SET_LANGUAGES_REQUEST = 'setLanguagesRequest';
export const SET_CURRENT_LANGUAGE = 'setCurrentLanguage';

export const SET_PAGES = 'setPages';
export const SET_PAGES_REQUEST = 'setPagesRequest';

export const SET_IMAGES = 'setImages';
export const SET_IMAGES_REQUEST = 'setImagesRequest';
